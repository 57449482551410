<template>
  <div>
    <PreLoaderSection v-if="isDataLoading" />
    <ProgramTable
      v-if="!isDataLoading"
      title="รายการยอดชมสูงสุดแต่ละสถานี"
      :sub-title="primeTimeTitle"
      :data="tableData"
      :header="tableHeader"
      @export="exportCSV" />
  </div>
</template>

<script>
import _ from 'lodash';
import { format } from 'date-fns';
import {
  loadPrimeTimeTopProgramData,
  getPrimeTimeCSVLink,
} from '@/primeTime/api';

import PreLoaderSection from '@/components/PreLoaderSection.vue';
import ProgramTable from '@/components/ProgramTable.vue';

export default {
  components: {
    ProgramTable,
    PreLoaderSection,
  },
  data() {
    return {
      tableData: null,
      tableError: null,
      tableHeader: null,
      sortBy: null,
      isLoading: null,
    };
  },
  created() {
    this.loadTableData();
  },
  computed: {
    platforms() {
      return _.get(this.$route, 'query.platforms', null);
    },
    startDate() {
      return _.get(this.$route, 'query.startPickedDate', null);
    },
    endDate() {
      return _.get(this.$route, 'query.endPickedDate', null);
    },
    graphFilters() {
      return {
        startPickedDate: this.startDate,
        endPickedDate: this.endDate,
        platforms: this.platforms,
      };
    },
    query() {
      return this.$route.query;
    },
    isDataLoading() {
      return _.isEmpty(this.tableData);
    },
    primeTimeTitle() {
      const startDate = new Date(`${this.startDate}Z`);
      const endDate = new Date(`${this.endDate}Z`);
      const dateFormat = 'dd/MM/yyyy';
      const timeFormat = 'HH:mm';
      const date = format(startDate, dateFormat);
      const startT = format(startDate, timeFormat);
      const endT = format(endDate, timeFormat);
      return `วันที่ ${date} เวลา ${startT} - ${endT}`;
    },
  },
  watch: {
    graphFilters() {
      this.loadTableData();
    },
  },
  methods: {
    async loadTableData() {
      this.isLoading = true;
      this.tableData = null;
      try {
        const response = await loadPrimeTimeTopProgramData(this.graphFilters);
        const { data } = response;
        const tableData = _.get(data, 'topProgramByStations.datas', null);
        this.tableData = _.orderBy(tableData, 'totalViewCount', 'desc');
        let tableHeader = _.get(data, 'topProgramByStations.header', null);
        if (this.platforms === 'app,web') {
          tableHeader = _.filter(tableHeader, header => header.name !== 'trueId');
        }
        if (this.platforms === 'trueId') {
          tableHeader = _.filter(tableHeader, header => header.name !== 'web' && header.name !== 'app' && header.name !== 'official');
        }
        this.tableHeader = tableHeader;
        this.isLoading = false;
      } catch (error) {
        this.tableData = null;
        this.tableError = _.get(error, 'message', error);
      }
      this.isLoading = false;
    },
    async exportCSV() {
      const teamName = _.get(this.$route, 'params.teamName', null);
      const filter = {
        ...this.graphFilters,
        teamName,
      };
      const exportData = await getPrimeTimeCSVLink(filter);
      const downloadURL = _.get(exportData, 'data.downloadURL', '#');
      window.open(downloadURL, '_blank');
    },
  },
};
</script>


<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

</style>
