<template>
  <div class="page prime-time-container">
    <template v-if="isLoading">
      <div class="container loading-container">
        <PreLoaderSection />
      </div>
    </template>
    <template v-if="!isLoading">
      <header class="page-header">
        <div class="container">
          <PrimeTimeFilter />
        </div>
      </header>
      <div class="container">
        <div class="graph-container">
          <SectionPrimeTimeGraph />
        </div>
        <SectionPrimeTimeList
          class="table-container" />
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { subHours } from 'date-fns';
import { generateLink } from '@/utils/generateLink';
import { transformDateToDisplay } from '@/utils/dateFormatter';

import PrimeTimeFilter from '@/primeTime/PrimeTimeFilter.vue';
import SectionPrimeTimeGraph from '@/primeTime/SectionPrimeTimeGraph.vue';
import SectionPrimeTimeList from '@/primeTime/SectionPrimeTimeList.vue';
import PreLoaderSection from '@/components/PreLoaderSection.vue';

export default {
  components: {
    PreLoaderSection,
    SectionPrimeTimeList,
    SectionPrimeTimeGraph,
    PrimeTimeFilter,
  },
  computed: {
    platforms() {
      return _.get(this.$route, 'query.platforms', null);
    },
    startDate() {
      return _.get(this.$route, 'query.startPickedDate', null);
    },
    endDate() {
      return _.get(this.$route, 'query.endPickedDate', null);
    },
    isLoading() {
      return !this.startDate && !this.endDate;
    },
  },
  created() {
    this.initDate();
  },
  methods: {
    generateLink,
    transformDateToDisplay,
    initDate() {
      if (!this.startDate) {
        const now = new Date();
        const last3Hours = subHours(now, 3);

        const startPickedDate = last3Hours.toISOString().slice(0, 16);
        const endPickedDate = now.toISOString().slice(0, 16);

        const query = {
          startPickedDate,
          endPickedDate,
        };
        this.$router.push({ query });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/page.scss';

.table-container {
  margin-top: $spacer;
  margin-bottom: $spacer * 0.75;
}

.loading-container {
  margin-top: $spacer;
}
</style>
