import qs from 'qs';
import axios from '@/modules/axios';

function loadPrimeTimeGraphData(filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axios.get(`/top-program-concurrent-graph${query}`);
}

function loadPrimeTimeTopProgramData(filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axios.get(`/report/top-program-by-stations${query}`);
}

function getPrimeTimeCSVLink(filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axios.get(`/export/top-program-by-stations${query}`);
}

export {
  loadPrimeTimeGraphData,
  loadPrimeTimeTopProgramData,
  getPrimeTimeCSVLink,
};
