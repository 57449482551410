<template>
  <div class="report-filter-container">
    <div class="date-picker-container report-filter">
      <label class="filter-label">วันที่</label>
      <DatePicker
        :dateRange="dateRange"
        :onChange="onSelectDateFromCalendar"
        enableSingleDatePicker
        dark />
    </div>
    <div class="time-range-container report-filter">
      <label class="filter-label">เวลา</label>
      <div
        @click.stop="onToggleTimePicker"
        class="time-picker-container">
        <i class="fas fa-clock"></i> {{timeString}}
      </div>
      <div
        v-show="showTimePicker"
        v-click-outside="onClickOutsideTimePicker"
        class="time-picker-modal">
        <div class="form-group">
          <label class="time-label">ตั้งแต่</label>
          <TimePicker
            @onChange="onStartTimeChanged"
            :value="timePickerStart"
            inline
            dark />
        </div>
        <div class="form-group">
          <label class="time-label">ถึง</label>
          <TimePicker
            @onChange="onEndTimeChanged"
            :value="timePickerEnd"
            :hour-range="endHoursRange"
            :minute-range="endMinutesRange"
            inline
            dark />
        </div>
        <div class="form-group confirm-button-container">
          <button
            @click.stop="onConfirmTime"
            type="button"
            class="btn btn-primary">
            ยืนยัน
          </button>
        </div>
      </div>
    </div>
    <div class="platform-container report-filter">
      <label class="filter-label">Platform ที่แสดงผล</label>
      <div class="range-container">
        <div
          @click="onCLickPlatformFilter(platform.key)"
          v-for="(platform, index) in platforms"
          :value="platform.key"
          :key="index"
          :class="`filter ${platform.key === platformFilter ? 'selected' : ''} ${platform.className}`">
          {{platform.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ClickOutside from 'vue-click-outside';
import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';

export default {
  components: {
    DatePicker,
    TimePicker,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      platforms: [
        { name: 'ทุก Platform', key: null, className: 'filter-all' },
        { name: 'เฉพาะ Official Platform', key: 'app,web', className: 'filter-official' },
        { name: 'เฉพาะ TrueID', key: 'trueId', className: 'filter-true-id' },
      ],
      showTimePicker: false,
      timePickerStart: '',
      timePickerEnd: '',
      startTimeH: 0,
      startTimeM: 0,
      endTimeH: 0,
      endTimeM: 0,
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    query() {
      return this.$route.query;
    },
    platformFilter() {
      return _.get(this.query, 'platforms', null);
    },
    startPickedDate() {
      return _.get(this.query, 'startPickedDate', null);
    },
    endPickedDate() {
      return _.get(this.query, 'endPickedDate', null);
    },
    dateRange() {
      return {
        range: null,
        startDate: this.startDate,
        endDate: this.endDate,
      };
    },
    timeString() {
      return `${this.timePickerStart} - ${this.timePickerEnd}`;
    },
    endHoursRange() {
      return [[parseInt(this.startTimeH, 10), 23]];
    },
    endMinutesRange() {
      if (this.startTimeH !== this.endTimeH) {
        return [[0, 59]];
      }
      return [[parseInt(this.startTimeM, 10), 59]];
    },
  },
  created() {
    this.initTime();
  },
  methods: {
    onToggleTimePicker() {
      this.showTimePicker = !this.showTimePicker;
    },
    initTime() {
      const startDate = new Date(`${this.startPickedDate}Z`);
      const endDate = new Date(`${this.endPickedDate}Z`);
      this.startDate = startDate;
      this.endDate = endDate;

      this.startTimeH = this.addLeadingZero(startDate.getHours());
      this.endTimeH = this.addLeadingZero(endDate.getHours());
      this.startTimeM = this.addLeadingZero(startDate.getMinutes());
      this.endTimeM = this.addLeadingZero(endDate.getMinutes());

      this.timePickerStart = `${this.startTimeH}:${this.startTimeM}`;
      this.timePickerEnd = `${this.endTimeH}:${this.endTimeM}`;
    },
    onCLickPlatformFilter(platforms) {
      if (platforms === this.platformFilter) {
        return;
      }
      if (!platforms) {
        const query = _.omit(this.query, ['platforms']);
        this.$router.push({ query });
      } else {
        this.$router.push({
          query: {
            ...this.query,
            platforms,
          },
        });
      }
    },
    addLeadingZero(number) {
      return number < 10 ? `0${number}` : `${number}`;
    },
    onSelectDateFromCalendar(rangeDate) {
      const [startY, startM, startD] = rangeDate.startDate.split('-');
      // const [endY, endM, endD] = rangeDate.endDate.split('-');

      const startDate = new Date(startY, startM - 1, startD, this.startTimeH, this.startTimeM, 0);
      const endDate = new Date(startY, startM - 1, startD, this.endTimeH, this.endTimeM, 0);
      const startISO = startDate.toISOString();
      const endISO = endDate.toISOString();
      const apiStart = startISO.slice(0, 16);
      const apiEnd = endISO.slice(0, 16);

      this.startDate = startDate;
      this.endDate = endDate;

      const query = {
        ...this.query,
        startPickedDate: apiStart,
        endPickedDate: apiEnd,
      };

      this.$router.push({ query });
    },
    onConfirmTime() {
      const startDate = new Date(this.startDate.setHours(this.startTimeH, this.startTimeM));
      const endDate = new Date(this.endDate.setHours(this.endTimeH, this.endTimeM));
      this.startDate = startDate;
      this.endDate = endDate;

      const startISO = startDate.toISOString();
      const endISO = endDate.toISOString();
      const apiStart = startISO.slice(0, 16);
      const apiEnd = endISO.slice(0, 16);

      const query = {
        ...this.query,
        startPickedDate: apiStart,
        endPickedDate: apiEnd,
      };
      this.showTimePicker = false;
      this.$router.push({ query });
    },
    onStartTimeChanged(data) {
      this.startTimeH = parseInt(data.data.H, 10);
      this.startTimeM = parseInt(data.data.mm, 10);
      this.timePickerStart = data.displayTime;
    },
    onEndTimeChanged(data) {
      this.endTimeH = parseInt(data.data.H, 10);
      this.endTimeM = parseInt(data.data.mm, 10);
      this.timePickerEnd = data.displayTime;
    },
    onClickOutsideTimePicker(event) {
      const targetElement = _.get(event, 'target.className', '');
      if (targetElement && targetElement === 'time-picker-overlay') {
        // Do noting if clicked on the parent element.
        return;
      }
      // Dismiss the modal if clicked on the elsewhere
      this.showTimePicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/page.scss';

::v-deep .reportrange-text {
  padding: 0.5rem 1rem !important;
}

::v-deep .daterangepicker:before, ::v-deep .daterangepicker:after {
  display: none;
}

::v-deep .daterangepicker.opensleft {
  right: unset;
}

::v-deep .dark .vue-daterange-picker {
  margin: $spacer * 0.5 0;
  margin-left: 0 !important;
  .reportrange-text {
    background: $primary-bg-lighter !important;
  }
  .date {
    font-size: $font-size-base !important;
    margin-left: 3px;
  }
}

.report-filter-container {
  .date-picker-container {
    flex: 0 0 25%;
  }

  .time-range-container {
    position: relative;
    flex: 0 0 25%;
  }

  .time-picker-modal {
    position: absolute;
    top: 74px;
    left: 0;
    padding-top: 10px;
    background-color: #3a3f48;
    border-radius: $border-radius-base;
    box-shadow: 1px 1px 20px rgb(0 0 0 / 20%);
    z-index: 3;

    .time-label {
      width: 60px;
      color: #FFF;
    }

    .time-input {
      padding: 3px;
      border-radius: $border-radius-base;
      border: none;
    }

    .form-group {
      padding: 10px 20px 0;
    }

    .confirm-button-container {
      border-top: 1px solid #484E55;
      margin-top: 6px;
      padding: $spacer 20px;
      text-align: right;

      .btn-primary {
        cursor: pointer;
        width: 110px;
      }
    }
  }

  .date-picker-container {
    display: flex;
    flex-direction: column;
  }
  .date-picker-container-mobile {
    display: none;
  }
}

.time-picker-container {
  cursor: pointer;
  background: #3A3F48;
  border: 1px solid #3A3F48 !important;
  color: #fff;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-radius: $border-radius-base;
  width: fit-content;
  .fas {
    margin-right: 2px;
  }
}

@media screen and (max-width: 767.98px) {
  .report-filter-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: $spacer;
    margin-top: 0;
    flex-direction: column;
    .report-filter {
      position: relative;
      flex: 0 0 100%;
      width: 100%;
    }
    .date-picker-container-mobile {
      display: flex;
      flex-direction: column;
      margin-bottom: $spacer;
    }
    .range-container {
      flex-wrap: wrap;
      .filter {
        margin: $spacer / 3;
      }
      .filter:last-child {
        margin-left: 0;
      }
    }
    .dark ::v-deep .vue-daterange-picker {
      min-width: 100% !important;
    }
    .time-range-container {
      flex: 0 0 100%;
      width: 100%;
    }
    ::v-deep .time-picker-container {
      width: 100%;
    }
    .report-filter {
      margin-bottom: $spacer;
    }
    .filter {
      text-align: center;
    }
    .filter-all {
      width: 48%;
      order: 1;
      margin-right: $spacer * 0.6;
    }
    .filter-true-id {
      width: 48%;
      order: 2;
      margin-right: 0;
    }
    .filter-official {
      width: 100%;
      order: 3;
    }
  }
}

@media (max-width: 576px) {
  .date-picker-modal {
    &.dark {
      ::v-deep .reportrange-text {
        display: block;
      }
    }
  }
}
</style>
