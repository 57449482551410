<template>
  <Section class="summary-total-chart-container">
    <PreLoaderSection v-if="isDataLoading" />
    <LineChart
      v-if="!isDataLoading"
      :series="series"
      :categories="categories"
      hide-time />
  </Section>
</template>

<script>
import _ from 'lodash';
import { loadPrimeTimeGraphData } from '@/primeTime/api';
import { transformDateToDisplay } from '@/utils/dateFormatter';
import { generateLink } from '@/utils/generateLink';
import { getStationDisplayName } from '@/utils/stationUtils';

import Section from '@/components/Section.vue';
import PreLoaderSection from '@/components/PreLoaderSection.vue';
import LineChart from '@/components/LineChart.vue';

export default {
  components: {
    LineChart,
    PreLoaderSection,
    Section,
  },
  data() {
    return {
      graphData: null,
      graphError: null,
      graphInterval: null,
      categories: null,
      series: [],
      isLoading: true,
    };
  },
  computed: {
    isSeriesEmpty() {
      return _.isEmpty(this.series);
    },
    isCategoriesEmpty() {
      return _.isEmpty(this.categories);
    },
    platforms() {
      return _.get(this.$route, 'query.platforms', null);
    },
    startDate() {
      return _.get(this.$route, 'query.startPickedDate', null);
    },
    endDate() {
      return _.get(this.$route, 'query.endPickedDate', null);
    },
    graphFilters() {
      return {
        startPickedDate: this.startDate,
        endPickedDate: this.endDate,
        platforms: this.platforms,
      };
    },
    isDataLoading() {
      return _.isEmpty(this.graphData);
    },
  },
  created() {
    this.loadGraphData();
  },
  watch: {
    graphFilters() {
      this.loadGraphData();
    },
  },
  methods: {
    async loadGraphData() {
      this.categories = null;
      this.series = [];
      this.graphData = null;
      try {
        const response = await loadPrimeTimeGraphData(this.graphFilters);
        const { data } = response;
        this.graphData = _.get(data, 'viewCounts', null);
        this.graphInterval = _.get(data, 'interval', null);
        this.initCategories();
        this.initSeries();
      } catch (error) {
        this.graphData = null;
        this.graphError = _.get(error, 'message', error);
      }
      this.isLoading = false;
    },
    generateLink,
    transformDateToDisplay,
    getTimeString() {
      if (this.startDate && this.endDate) {
        return `${this.transformDateToDisplay(this.startDate)} - ${this.transformDateToDisplay(this.endDate)}`;
      }
      if (this.dateRange) {
        if (this.dateRange === 'last24hours') return 'Last 24 Hours';
        if (this.dateRange === 'last7days') return 'Last 7 Days';
        if (this.dateRange === 'last30days') return 'Last 30 Days';
      }
      return 'Last 24 Hours';
    },
    initSeries() {
      const earliestStartTime = this.getEarliestStartTime();
      const formatted = _.chain(this.graphData)
        .sortBy('stationName')
        .map((source) => {
          const fillSource = this.fillData(source.data, earliestStartTime);
          const innerData = _.map(fillSource, innerSource => innerSource.viewCount);
          return {
            data: innerData,
            name: _.get(source.data, '0.stationName'),
          };
        })
        .value();

      // group station
      this.groupedStation = _.groupBy(formatted, 'name');
      _.forOwn(this.groupedStation, (value, key) => {
        const total = value.map(item => item.data);
        const stationSummary = total.reduce((r, a) => a.map((b, i) => (r[i] || 0) + b), []);
        const groupedData = {
          data: stationSummary,
          name: getStationDisplayName(key),
        };
        this.series.push(groupedData);
      });
    },
    initCategories() {
      const dateArray = _.map(this.graphData, list => _.map(list.data, data => data.date));
      this.categories = _.head(dateArray);
    },
    getEarliestStartTime() {
      return _.head(this.categories);
    },
    fillData(sourceData, startTime) {
      let filledData = null;

      filledData = this.checkLostDataAtMiddle(sourceData);
      filledData = this.checkLostDataAtStart(filledData, startTime);
      return filledData;
    },
    checkLostDataAtMiddle(sourceData) {
      const filledData = _.cloneDeep(sourceData);
      sourceData.forEach((period, index) => {
        const nextPeriodData = sourceData[index + 1];
        if (nextPeriodData) {
          const thisPeriodDate = new Date(period.date);
          const nextPeriodDate = new Date(nextPeriodData.date);
          const diffTime = nextPeriodDate - thisPeriodDate;
          // If difference time more than 15 minutes, Mock new data.
          if (diffTime > this.intervalTime) {
            // Number of mock data that we need to add.
            const numberOfLost = (diffTime / this.intervalTime) - 1;
            for (let i = 1; i <= numberOfLost; i += 1) {
              const time = nextPeriodDate.getTime();
              const newDate = new Date(time + (this.intervalTime * i));
              const mockUp = {
                ...period,
                date: newDate,
                viewCount: null,
              };
              // Add new data to next index.
              filledData.splice(index + 1, 0, mockUp);
            }
          }
        }
      });
      return filledData;
    },
    checkLostDataAtStart(sourceData, startTime) {
      const filledData = _.cloneDeep(sourceData);
      const firstPeriod = sourceData[0];

      const earliestStartTime = new Date(startTime);
      const firstPeriodTime = new Date(firstPeriod.date);
      // Check difference time between start of the graph and start time of this line.
      const diffTime = firstPeriodTime - earliestStartTime;

      if (diffTime > this.intervalTime) {
        // Number of mock data that we need to add.
        const numberOfLost = (diffTime / this.intervalTime);
        for (let i = 1; i <= numberOfLost; i += 1) {
          const newDate = new Date((this.intervalTime * i));
          const mockUp = {
            ...firstPeriod,
            date: newDate,
            viewCount: null,
          };
          // Add new data to next index.
          filledData.splice(i - 1, 0, mockUp);
        }
      }
      return filledData;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

.section-body {
  max-height: 450px;
}
.apexcharts-legend {
  .apexcharts-legend-series {
    display: flex !important;
    justify-content: center !important;
    align-self: center !important;
    align-items: center !important;
  }
}

.time {
  font-size: $font-size-base * 1.25;
  color: #bdbdbd;
}
</style>
